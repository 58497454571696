import { Heading } from "@chakra-ui/react";
import { graphql } from "gatsby";
import * as React from "react";

import ExhibitionCard from "../components/ExhibitionCard";
import Layout from "../components/Layout";

import { ExhibitionI } from "../interfaces";

const Exhibitions = ({
  data: {
    allContentfulExhibition: { nodes },
  },
  pageContext: { title, description, language, localeSwitcher },
  location,
}: {
  data: { allContentfulExhibition: { nodes: ExhibitionI[] } };
  pageContext: {
    title: string;
    description: string;
    language: string;
    localeSwitcher: { [key: string]: string };
  };
  location: { pathname: string };
}) => {
  return (
    <Layout
      title={title}
      description={description}
      locale={language}
      location={location}
      limitWidth
      localeSwitcher={localeSwitcher}
    >
      <Heading as="h2" marginBottom="2rem">
        {title}
      </Heading>
      {nodes?.map((data, index) => (
        <ExhibitionCard
          data={data}
          locale={language}
          key={index}
          textColor="#000"
        />
      ))}
    </Layout>
  );
};

export default Exhibitions;

export const pageQuery = graphql`
  query ExhibitionsQuery($language: String!) {
    allContentfulExhibition(
      filter: {
        node_locale: { eq: $language }
        title: { ne: "DummyExhibition" }
      }
      sort: { dateStart: DESC }
    ) {
      nodes {
        ...ContentfulExhibitionFragment
      }
    }
  }
`;
